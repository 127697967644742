import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Confirmation from "./pages/Confirmation";
import OrderError from "./pages/OrderError";
import ConfirmationVipps from "./pages/ConfirmationVipps";
import AdminHome from "./pages/AdminHome";
import OrderPage from "./pages/OrderPage";
import Layout from "./components/Layout";
import { OrderProvider } from "./context/OrderContext";
import { OrderStateContextProvider } from "./context/OrderStateContext";
import { DeliveryContextProvider } from "./context/DeliveryContext";
import { ShowFooterContextProvider } from "./context/ShowFooterContext";
import { CategoryContextProvider } from "./context/CategoryContext";
import { RenderHomeComponentsContextProvider } from "./context/RenderHomeComponentsContext";
import NotFound from "./components/NotFound";
/*eslint-disable*/

function App() {
  return (
    <Router>
      <RenderHomeComponentsContextProvider>
        <CategoryContextProvider>
          <ShowFooterContextProvider>
            <OrderStateContextProvider>
              <DeliveryContextProvider>
                <OrderProvider>
                  <Layout>
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/confirmation" element={<Confirmation />} />
                      <Route path="/error" element={<OrderError />} />
                      <Route path="*" element={<NotFound />} />
                      <Route
                        path="/confirmation-vipps"
                        element={<ConfirmationVipps />}
                      />
                      <Route path="/admin/0AAE9C6F2KzJtkd3pLeb" element={<AdminHome />} />
                      <Route path="/order/:orderId" element={<OrderPage />} />
                    </Routes>
                  </Layout>
                </OrderProvider>
              </DeliveryContextProvider>
            </OrderStateContextProvider>
          </ShowFooterContextProvider>
        </CategoryContextProvider>
      </RenderHomeComponentsContextProvider>
    </Router>
  );
}

export default App;
