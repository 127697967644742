import React from "react";
import css from "../styles/SectionProductText.module.scss";
import { Product } from "../types";

export interface Props {
  text: string;
  product: Product;
  number: number;
  showRepairForm?: () => void;
}

const SectionProductText: React.FC<Props> = function ({
  text,
  number,
  showRepairForm,
}) {
  return (
    <>
      <li className={css.SectionProductText}>
        <button onClick={showRepairForm}>
          <p className={css.Text}>{text}</p>
          {number > 0 && (
            <span className={css.Number}>
              <span>{number}</span>
            </span>
          )}
        </button>
      </li>
    </>
  );
};

export default SectionProductText;
