import React from "react";
import css from "../../styles/Confirmation.module.scss";

export interface Props {
  title: string;
  paragraph: string;
  paragraph2?: string;
  style?: React.CSSProperties;
}

const B2BOrder = () => {
  return (
    <div className={css.ConfirmationSection}>
      <ol className={css.NumberedList}>
        <li className={css.NumberedListElement}>
          <p className={css.ListText}>Sjekk epost for bestillingsdetaljer</p>
        </li>
        <li className={css.NumberedListElement}>
          <p className={css.ListText}>
            Vi plukker opp på avtalt tidspunkt. Haster det, må du gi oss
            beskjed, eller levere selv
          </p>
        </li>
        <li className={css.NumberedListElement}>
          <p className={css.ListText}>
            Vi leverer til dere på avtalt tidspunkt, normalt innen 10
            virkedager.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default B2BOrder;
