import React from "react";
import css from "../styles/OrderError.module.scss";

const OrderError: React.FC = () => {
  window.scrollTo({
    top: 0,
  });
  return (
    <main className={css.OrderError}>
      <p>Noe gikk galt i å sende bestillingen din. Prøv igjen senere.</p>
    </main>
  );
};

export default OrderError;
