/* eslint-disable prefer-const */
/* eslint-disable */
import React, { useContext, useState } from "react";
import { useFetchData } from "../hooks/useFetchData";
import css from "../styles/Home.module.scss";
import NavListContainer from "../components/NavListContainer";
import CategoriesContainer from "../components/CategoriesContainer";
import SectionOrderSummary from "../components/OrderSummary/SectionOrderSummary";
import OrderSummary from "../components/OrderSummary/OrderSummary";
import InfoBox from "../components/InfoBox";
import CheckoutForm from "../components/CheckoutForm/CheckoutForm";
import { OrderContext, OrderContextType, Order } from "../context/OrderContext";
import { OrderStateContext } from "../context/OrderStateContext";
import { RenderHomeComponentsContext } from "../context/RenderHomeComponentsContext";
import { analytics } from "../firebaseConfig";
import Popup from "../components/popup"; 
import PopupPricesContext, { PopupPrices } from "../context/PopupPricesContext";


const Home: React.FC = () => {
  const { check, storage } = useContext(OrderContext) as OrderContextType;

  const [select, setSelect] = useState<string>("clothes");
  const { data } = useFetchData(select);
  const [openOrders, setOpenOrders] = useState<boolean>(false);
  const [openCheckout, setOpenCheckout] = useState<boolean>(false);
  const { setOrderState } = useContext(OrderStateContext);
  const { renderHomeComponents } = useContext(RenderHomeComponentsContext);

  // Definer de nye tilstandene her
  const [popupPrices, setPopupPrices] = useState<PopupPrices>(null);
  const [validCode, setValidCode] = useState<string>('');
  const [isCodeActive, setIsCodeActive] = useState(false); // add this line

  function sumPriceOrders(array: Order[]): number {
    let price = 0;
    if (array !== undefined) {
      array.forEach((order: Order) => {
        price = price + order.price;
      });
    }

    return price;
  }

  const handleStates = () => {
    setOrderState(true);
    setOpenOrders(!openOrders);
    analytics.logEvent("open_orders");
  };

  return (
    <PopupPricesContext.Provider 
    value={{
      popupPrices, 
      setPopupPrices, 
      validCode, 
      setValidCode, 
      isCodeActive,  // Trengs ikke lenger / brukes ikke?
      setIsCodeActive  // Trengs ikke lenger / brukes ikke?
    }}
  >
    <main className={css.Home}>
      <CategoriesContainer select={select} setSelect={setSelect} />

      {data != undefined && <NavListContainer problems={data} />}
      {renderHomeComponents && (
        <>
          <InfoBox
            title={"Hva er Fikse?"}
            paragraph={
              "Vi gjør reparasjoner og tilpasninger på klær, sko og tilbehør - helt enkelt. Du får prisanslaget på forhånd og vi garanterer at jobben blir gjort skikkelig."
            }
            style={{ marginBottom: "3rem" }}
          />
          <div className={css.Link}>
            <a href="https://blogg-fikse.squarespace.com/about">
              Mer om oss{" "}
              <img
                src="images/right-faced-vector.svg"
                alt="right-faced-vector"
                className={css.Icon}
              />
            </a>
          </div>
          <figure>
            <img
              className={css.DecorativeImg}
              src="images/cover-photo.png"
              alt="cover-photo"
            />
          </figure>

          <InfoBox
            title={"Hvor er Fikse?"}
            paragraph={
              "Fikse finner du både online og fysisk. Du kan levere på et av våre studio eller dropoff punkter. På studio hjelper vi deg med mål og råd uten ekstra kostnad."
            }
            paragraph2={
              "Du kan også bestille henting og levering (+ 89.- per vei)."
            }
          />
          <div className={css.Link}>
            <a href="https://blogg-fikse.squarespace.com/her-er-vi">
              Våre studio{" "}
              <img
                src="images/right-faced-vector.svg"
                alt="right-faced-vector"
                className={css.Icon}
              />
            </a>
          </div>
        </>
      )}
      {check && (
        <SectionOrderSummary orders={storage} setOpen={() => handleStates()} />
      )}
      {openOrders && (
        <OrderSummary
          orders={storage}
          setOpenOrders={() => {
            setOpenOrders(!openOrders);
            analytics.logEvent("close_orders");
          }}
          setOpenCheckout={() => {
            setOpenCheckout(!openCheckout);
            analytics.logEvent("open_checkout");
          }}
          sumPriceOrders={() => sumPriceOrders(storage)}
        />
      )}
      {openCheckout && (
        <CheckoutForm
          setOpen={() => {
            setOpenCheckout(!openCheckout);
            analytics.logEvent("close_checkout");
          }}
          sumPriceOrders={() => sumPriceOrders(storage)}
        />
      )}
            <Popup />
             </main>
    </PopupPricesContext.Provider>
    

    
  );
};

export default Home;
