import React, { useContext } from "react";
import css from "../styles/CategoryBox.module.scss";
import { CategoryContext } from "../context/CategoryContext";

export interface Props {
  text: string;
  id: string;
  imgPath: string;
  disabled: boolean;
  width: string;
  toggleStates: (e: HTMLInputElement, activeId: string) => void;
  activeId: string;
}

const CategoryBox: React.FC<Props> = function ({
  text,
  id,
  imgPath,
  disabled,
  width,
  toggleStates,
  activeId,
}) {
  const { category } = useContext(CategoryContext);

  return (
    <>
      <input
        type="radio"
        id={id}
        name="category"
        value={id}
        defaultChecked={id === category}
        disabled={disabled}
        onClick={(e) => {
          toggleStates(e.target as HTMLInputElement, id);
        }}
      />
      <label
        htmlFor={id}
        className={
          activeId === id
            ? `${css.CategoryBox} ${css.CategoryActive}`
            : css.CategoryBox
        }
      >
        {text}
        <div className={css.CategoryImage}>
          <img
            className={css.CategoryImg}
            src={imgPath}
            width={width}
            alt="category-img"
          />
        </div>
      </label>
    </>
  );
};

export default CategoryBox;
