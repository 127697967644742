/* eslint-disable*/
import React, { Dispatch, SetStateAction, createContext } from 'react';

export type PopupPrices = {
  bigRepair: number;
  smallRepair: number;
} | null;

export type PopupPricesContextType = {
  popupPrices: PopupPrices;
  setPopupPrices: Dispatch<SetStateAction<PopupPrices>>;
  validCode: string;
  setValidCode: Dispatch<SetStateAction<string>>;
  bigRepair?: number; // Assuming bigRepair is a number
  smallRepair?: number; // Assuming bigRepair is a number
  isCodeActive: boolean; // new
  setIsCodeActive: Dispatch<SetStateAction<boolean>>; // new
}

export const PopupPricesContext = createContext<PopupPricesContextType>({
  popupPrices: null,
  setPopupPrices: () => {},
  validCode: localStorage.getItem('officeCode') ?? '',
  setValidCode: () => {},
  isCodeActive: false, // new
  setIsCodeActive: () => {} // new
});

export default PopupPricesContext;