/* eslint-disable prefer-const */
import React, { useEffect, useState, createContext } from "react";
import { RouteProps } from "react-router-dom";

export type Order = {
  mainCategory: string;
  category: string;
  product: string;
  price: number;
  description: string;
  id: string;
  eligibleForExpress: boolean;
};

export type OrderContextType = {
  check: boolean;
  setCheck: (value: boolean) => void;
  storage: Order[];
  setStorage: (value: Order[]) => void;
};

type Props = RouteProps;

export const OrderContext = createContext<OrderContextType | null>(null);

export const OrderProvider: React.FC<Props> = ({ children }: RouteProps) => {
  const [storage, setStorage] = useState<Order[]>([]);
  const [check, setCheck] = useState<boolean>(false);

  useEffect(() => {
    const obj = localStorage.getItem("currentOrder");

    if (obj) {
      const array = JSON.parse(obj);
      if (array.length) {
        setStorage(JSON.parse(obj));
        setCheck(true);
      }
    }
  }, []);

  return (
    <OrderContext.Provider value={{ check, setCheck, storage, setStorage }}>
      {children}
    </OrderContext.Provider>
  );
};
