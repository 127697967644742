/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { firestore } from "../firebaseConfig";
import css from "../styles/OrderPage.module.scss";

export interface Order {
  companyCode: string;
  contactInformation: {
    city: string;
    email: string;
    floor: string;
    name: string;
    phoneNumber: string;
    postCode: string;
    street: string;
    streetNumber: string;
  };
  dateOrdered: string;
  deliveryFrom: string;
  deliveryPrice: number;
  deliveryTo: string;
  dropOffLocation: string;
  express: boolean;
  expressPrice: number;
  order: {
    category: string;
    description: string;
    eligibleForExpress: boolean;
    id: string;
    mainCategory: string;
    price: number;
    product: string;
  }[];
  paid: boolean;
  pickUpLocation: string;
}

const OrderPage: React.FC = () => {
  const [orderData, setOrderData] = useState<Order | null>(null);
  const orderId = localStorage.getItem("orderId");

  useEffect(() => {
    const fetchOrderData = async () => {
      if (!orderId) {
        console.error("Order ID not found in localStorage");
        return;
      }

      try {
        const docRef = firestore.collection("orders").doc(orderId);
        const doc = await docRef.get();
        if (doc.exists) {
          setOrderData(doc.data() as Order);
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error getting document:", error);
      }
    };

    fetchOrderData();
  }, [orderId]);

  if (!orderData) {
    return <div className="text-center mt-10">Laster bestillingsdetaljer...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md mt-10">
      <h1 className="text-2xl font-bold mb-4">Bestillingsdetaljer</h1>
      <div className="mb-6">
        <h2 className="text-xl font-semibold">Kundeinformasjon</h2>
        {orderData.contactInformation.name && <p><strong>Navn:</strong> {orderData.contactInformation.name}</p>}
        {orderData.contactInformation.email && <p><strong>E-post:</strong> {orderData.contactInformation.email}</p>}
        {orderData.contactInformation.phoneNumber && <p><strong>Telefon:</strong> {orderData.contactInformation.phoneNumber}</p>}
        {(orderData.contactInformation.street || orderData.contactInformation.streetNumber || orderData.contactInformation.postCode || orderData.contactInformation.city) && (
          <p><strong>Adresse:</strong> {orderData.contactInformation.street} {orderData.contactInformation.streetNumber}, {orderData.contactInformation.postCode} {orderData.contactInformation.city}</p>
        )}
      </div>
      <div className="mb-6">
        <h2 className="text-xl font-semibold">Bestillingsinformasjon</h2>
        {orderData.dateOrdered && <p><strong>Bestilt dato:</strong> {orderData.dateOrdered}</p>}
        {orderData.companyCode && <p><strong>Selskap:</strong> {orderData.companyCode}</p>}
        {orderData.deliveryFrom && <p><strong>Levering fra:</strong> {orderData.deliveryFrom}</p>}
        {orderData.deliveryTo && <p><strong>Levering til:</strong> {orderData.deliveryTo}</p>}
        {orderData.deliveryPrice > 0 && <p><strong>Leveringspris:</strong> {orderData.deliveryPrice} kr</p>}
        <p><strong>Ekspress:</strong> {orderData.express ? "Ja" : "Nei"}</p>
        {orderData.express && orderData.expressPrice > 0 && <p><strong>Ekspresspris:</strong> {orderData.expressPrice} kr</p>}
      </div>
      <div className="mb-6">
        <h2 className="text-xl font-semibold">Ordre detaljer</h2>
        {orderData.order.map((item, index) => (
          <div key={index} className="border-t border-gray-200 pt-4 mt-4">
            {item.product && <p><strong>Produkt:</strong> {item.product}</p>}
            {item.category && <p><strong>Kategori:</strong> {item.category}</p>}
            {item.description && <p><strong>Beskrivelse:</strong> {item.description}</p>}
            {item.price > 0 && <p><strong>Pris:</strong> {item.price} kr</p>}
          </div>
        ))}
      </div>
      <div className="mt-6">
        <h2 className="text-xl font-semibold">Betalingsstatus</h2>
        <p><strong>Betalt:</strong> {orderData.paid ? "Ja" : "Nei"}</p>
      </div>
    </div>
  );
};

export default OrderPage;
