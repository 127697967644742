import { createContext, useState } from "react";

type OrderStateContextType = {
  orderState: boolean;
  setOrderState: (orderState: boolean) => void;
};
const OrderStateContext = createContext<OrderStateContextType>({
  orderState: false,
  setOrderState: () => {},
});

interface Props {
  children: React.ReactNode;
}

const OrderStateContextProvider: React.FC<Props> = ({ children }: Props) => {
  const [orderState, setOrderState] = useState<boolean>(false);

  return (
    <OrderStateContext.Provider value={{ orderState, setOrderState }}>
      {children}
    </OrderStateContext.Provider>
  );
};

export { OrderStateContext, OrderStateContextProvider };
