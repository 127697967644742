import { createContext, useState } from "react";

interface deliveryProps {
  totalPrice: number;
  isSent: boolean;
  isCompanyOrder: boolean;
}

type OrderStateContextType = {
  deliveryData: deliveryProps;
  setDeliveryData: (deliveryData: deliveryProps) => void;
};
const DeliveryContext = createContext<OrderStateContextType>({
  deliveryData: {
    totalPrice: 0,
    isSent: false,
    isCompanyOrder: false,
  },
  setDeliveryData: () => {},
});

interface Props {
  children: React.ReactNode;
}

const DeliveryContextProvider: React.FC<Props> = ({ children }: Props) => {
  const [deliveryData, setDeliveryData] = useState<deliveryProps>({
    totalPrice: 0,
    isSent: false,
    isCompanyOrder: false,
  });

  return (
    <DeliveryContext.Provider value={{ deliveryData, setDeliveryData }}>
      {children}
    </DeliveryContext.Provider>
  );
};

export { DeliveryContext, DeliveryContextProvider };
