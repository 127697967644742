import { useState, useEffect } from "react";
import { DataListType } from "../types";
import { firestore } from "../firebaseConfig";
/* eslint-disable */

export const useFetchData = (category: string) => {
  const [data, setData] = useState<Array<DataListType>>();

  useEffect(() => {
    const getDocument = async () => {
      const docRef = firestore.collection("dynamicPages").doc("products");
      const doc = await docRef.get();
      if (doc.exists) {
        const data = doc.data();
        if (data) {
          setData(data[category]);
        }
      }
    };
    getDocument();
  }, [category]);

  return { data };
};