import React from "react";
import css from "../styles/NavLink.module.scss";

export interface Props {
  text: string;
  link: string;
  icon: boolean;
  style?: React.CSSProperties;
}

const NavLink: React.FC<Props> = function ({ text, link, icon, style }) {
  return (
    <div className={css.NavLink} style={{ ...style }}>
      <a href={link}>
        <p className={css.Text}>{text}</p>
        {icon && (
          <img
            src="images/right-faced-vector.svg"
            alt="right-faced-vector"
            className={css.Icon}
          />
        )}
      </a>
    </div>
  );
};

export default NavLink;
