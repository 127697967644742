/* eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import css from "../../styles/RepairForm.module.scss";
import OptionBox from "./OptionBox";
import {
  OrderContext,
  OrderContextType,
  Order,
} from "../../context/OrderContext";
import { OrderStateContext } from "../../context/OrderStateContext";
import { CategoryContext } from "../../context/CategoryContext";
import uuid from "react-uuid";

import { Product } from "../../types";
import PopupPricesContext from '../../context/PopupPricesContext';

export interface Props {
  category: string;
  product: Product;
}

const RepairForm: React.FC<Props> = ({ category, product }) => {
  const context = useContext(PopupPricesContext);

  if (!context) {
    throw new Error("PopupPricesContext is undefined, please verify the context provider");
  }
  
  const { validCode, setValidCode, popupPrices, setPopupPrices } = context;
  const { check, setCheck, storage, setStorage } = useContext(OrderContext) as OrderContextType;
  const [order, setOrder] = useState<Order>();
  const { category: mainCategory } = useContext(CategoryContext);
  const { setOrderState } = useContext(OrderStateContext);
  const [price, setPrice] = useState<number>();
  const [input, setInput] = useState<string>("");
  const [isCodeActive, setIsCodeActive] = useState(false);

  const setPriceFunction = (prices: number) => {
    setPrice(prices);
  };

  useEffect(() => {
    if (order !== undefined) {
      setOrderState(false);
      setCheck(true);
      const newStorage = [...storage, order];
      setStorage(newStorage);
    }
  }, [order, check, storage]);

  // This effect will trigger a re-render when popupPrices changes
  useEffect(() => {
}, [popupPrices, isCodeActive]);

  function handleSubmit(
    mainCategory: string,
    newCategory: string,
    newtype: string,
    newPrice: number,
    newInput: string,
    eligibleForExpress: boolean
  ): void {
    setOrder({
      mainCategory: mainCategory, // Overordnet kategori
      category: newCategory,
      product: newtype,
      price: newPrice,
      description: newInput,
      id: uuid(),
      eligibleForExpress: eligibleForExpress,
    });
  }

  return (
    <div className={css.RepairForm}>
      <div className={css.Overlay} onClick={() => setOrderState(false)}></div>
      <div className={css.Container}>
      {product.smallRepair && (
  <OptionBox
    title={"Liten fiks"}
    paragraph={product.descriptionSmall || product.description}
    price={validCode && product?.popupSmall !== undefined 
      ? product?.popupSmall 
      : (product?.smallRepair)}
    selected={true}
    setPrice={setPriceFunction}
  />
)}
{product.bigRepair && (
  <OptionBox
    title={"Stor fiks"}
    paragraph={product.descriptionBig || product.description}
    price={validCode && product?.popupBig !== undefined 
      ? product?.popupBig
      : (product.bigRepair)}
    selected={false}
    setPrice={setPriceFunction}
  />
)}

        {product.repair && (
          <OptionBox
            title={"Fiks"}
            paragraph={product.description}
            price={product.repair}
            selected={true}
            setPrice={setPriceFunction}
          />
        )}

        {category === "Bunad" && (
          <div className={css.Warning}>
            <a href="https://blogg-fikse.squarespace.com/bunad">
              <span className={css.WarningSpan}>
                <p>
                  <span className={css.WarningTitle}>
                    OBS! <img src="images/right-faced-vector.svg"></img>
                  </span>{" "}
                  Disse type reparasjonen må avtales med skredder...
                </p>
                <p>Book din time her</p>
              </span>
            </a>
          </div>
        )}

        <textarea
          className={css.input}
          rows={1}
          placeholder="Beskriv det som skal fikses, f eks. antall cm, farge på glidelås."
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <div
          className={css.button}
          onClick={() =>
            handleSubmit(
              mainCategory, 
              category,
              product.type,
              price ?? 0,
              input,
              product.eligibleForExpress
            )
          }
        >
          <p>Legg til i kassen</p>
          <img src="images/add-cross.svg" alt="add-cross" />
        </div>
      </div>
    </div>
  );
};

export default RepairForm;
