import React, { useState, useContext } from "react";
import { RouteProps, useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import css from "../styles/Layout.module.scss";
import { OrderStateContext } from "../context/OrderStateContext";
import { ShowFooterContext } from "../context/ShowFooterContext";

type Props = RouteProps;

const Layout: React.FC<Props> = function ({ children }: RouteProps) {
  const [open, setOpen] = useState<boolean>(false);
  const { orderState } = useContext(OrderStateContext);
  const { showFooter } = useContext(ShowFooterContext);
  const location = useLocation();

  return (
    <div
      className={!open ? css.Layout : `${css.Layout} ${css.LayoutFixed}`}
      style={
        orderState || open ? { position: "fixed" } : { position: "static" }
      }
    >
      <header style={{ position: "relative", zIndex: "2" }}>
        <Navbar open={open} setOpen={setOpen} />
      </header>
      <div
        className={css.Overlay}
        style={open === true ? { display: "unset" } : { display: "none" }}
      ></div>
      {children}

      {showFooter && <Footer path={location.pathname} />}
    </div>
  );
};

export default Layout;
