import React, { useEffect } from "react";
import css from "../../styles/OptionBox.module.scss";

export interface Props {
  title: string;
  price: number;
  paragraph?: string;
  selected?: boolean;
  setPrice?: (price: number) => void;
  style?: React.CSSProperties;
}

const OptionBox: React.FC<Props> = ({
  title,
  price,
  paragraph,
  selected,
  setPrice,
}) => {
  useEffect(() => {
    if (selected && setPrice) setPrice(price);
  }, []);
  return (
    <>
      {setPrice && (
        <div className={css.OptionBox}>
          <input
            type="radio"
            id={title}
            name="category"
            value="shoes"
            defaultChecked={selected}
            onChange={() => setPrice(price)}
          />
          <label htmlFor={title}>
            <div className={css.Description}>
              <span>{title}</span>
              <span>{price}.-</span>
            </div>
            {paragraph && <p>{paragraph}</p>}
          </label>
        </div>
      )}
    </>
  );
};

export default OptionBox;
