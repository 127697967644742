import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import "firebase/compat/firestore";
import "firebase/compat/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDLtQUa-vpahe_L9wihla09OfTFmoSo94w",
  authDomain: "fiksefirstapp.firebaseapp.com",
  projectId: "fiksefirstapp",
  storageBucket: "fiksefirstapp.appspot.com",
  messagingSenderId: "1036858581379",
  appId: "1:1036858581379:web:9a123146fafacd27b016b6",
};

firebase.initializeApp(firebaseConfig);

const functions = firebase.functions();
const firestore = firebase.firestore();
const analytics = firebase.analytics();

export { firebase, functions, firestore, analytics };
