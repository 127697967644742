import { createContext, useState } from "react";

type CategoryContextType = {
  category: string;
  setCategory: (category: string) => void;
};
const CategoryContext = createContext<CategoryContextType>({
  category: "clothes",
  setCategory: () => {},
});

interface Props {
  children: React.ReactNode;
}

const CategoryContextProvider: React.FC<Props> = ({ children }: Props) => {
  const [category, setCategory] = useState<string>("clothes");

  return (
    <CategoryContext.Provider value={{ category, setCategory }}>
      {children}
    </CategoryContext.Provider>
  );
};

export { CategoryContext, CategoryContextProvider };
