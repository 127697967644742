import React from "react";
import css from "../../styles/Confirmation.module.scss";

export interface Props {
  title: string;
  paragraph: string;
  paragraph2?: string;
  style?: React.CSSProperties;
}

const DropOffOrder = () => {
  const fikseStudios = [
    {
      name: "Studio Fikse",
      link: "https://blogg-fikse.squarespace.com/her-er-vi",
    },
  ];

  return (
    <div className={css.ConfirmationSection}>
      <ol className={css.NumberedList}>
        <li className={css.NumberedListElement}>
          <p className={css.ListText}>
            Du får nå bekreftelse på epost. Kom innom med det du skal fikse.
          </p>
          <ul className={css.ListLink}>
            {fikseStudios.map((studio, i) => {
              return (
                <li key={i} className={css.ListLinkItem}>
                  <a href={studio.link}>
                    <p>{studio.name}</p>
                    <img src="images/right-faced-vector.svg" />
                  </a>
                </li>
              );
            })}
          </ul>
        </li>
        <li className={css.NumberedListElement}>
          <p className={css.ListText}>
            Du får beskjed så raskt reparasjonen er fullført og klar til henting
          </p>
        </li>
        <li className={css.NumberedListElement}>
          <p className={css.ListText}>
            Skulle det dukke opp noe eller reparasjonen blir dyrere enn
            forventet vil vi ta kontakt med deg, og du vil få mulighet til å
            kansellere bestillingen.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default DropOffOrder;
