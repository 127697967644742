import React from "react";
import css from "../styles/SectionNavText.module.scss";

export interface Props {
  problem: string;
  callback?: () => void;
}

const SectionNavText: React.FC<Props> = function ({ problem, callback }) {
  return (
    <>
      <li className={css.SectionNavText}>
        <button className={css.Button} onClick={callback}>
          <p>{problem}</p>
        </button>
      </li>
    </>
  );
};

export default SectionNavText;
