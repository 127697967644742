import React, { useContext, useEffect } from "react";
import css from "../styles/Confirmation.module.scss";
import { ShowFooterContext } from "../context/ShowFooterContext";

const ConfirmationVipps: React.FC = () => {
  const { setShowFooter } = useContext(ShowFooterContext);

  window.scrollTo({
    top: 0,
  });

  useEffect(() => {
    setShowFooter(true);
  }, []);

  return (
    <main className={css.Confirmation}>
      <div className={css.IntroText}>
        <p className={css.Price}>
          <span>Takk!</span>
          Ved fullført betaling er bestillingen sendt.
        </p>
      </div>
    </main>
  );
};

export default ConfirmationVipps;
