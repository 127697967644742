import React, { useState, useEffect } from "react";
import css from "../../styles/SectionOrderSummary.module.scss";
import { Order } from "../../context/OrderContext";

export interface Props {
  orders: Order[];
  setOpen: () => void;
}

const SectionOrderSummary: React.FC<Props> = ({ orders, setOpen }) => {
  const [unit, setUnit] = useState<string>("reparasjon");
  const startingPrice = 0;
  const totalPrice = orders?.reduce(
    (sum, order) => sum + order.price,
    startingPrice
  );

  useEffect(() => {
    orders.length > 1 ? setUnit("reparasjoner") : setUnit("reparasjon");
  }, [orders]);

  return (
    <div className={css.SectionOrderSummary}>
      <button className={css.Wrapper} onClick={setOpen}>
        <p className={css.NumberOfOrders}>
          {orders.length} {unit}
        </p>
        <p>{totalPrice}.-</p>
        <img src="images/upper-faced-vector.svg" alt="upper-faced-vector" />
      </button>
    </div>
  );
};

export default SectionOrderSummary;
