import { createContext, useState } from "react";

type ShowFooterContextType = {
  showFooter: boolean;
  setShowFooter: (showFooter: boolean) => void;
};
const ShowFooterContext = createContext<ShowFooterContextType>({
  showFooter: true,
  setShowFooter: () => {},
});

interface Props {
  children: React.ReactNode;
}

const ShowFooterContextProvider: React.FC<Props> = ({ children }: Props) => {
  const [showFooter, setShowFooter] = useState<boolean>(true);

  return (
    <ShowFooterContext.Provider value={{ showFooter, setShowFooter }}>
      {children}
    </ShowFooterContext.Provider>
  );
};

export { ShowFooterContext, ShowFooterContextProvider };
