import React from "react";
import css from "../../styles/Confirmation.module.scss";

export interface Props {
  title: string;
  paragraph: string;
  paragraph2?: string;
  style?: React.CSSProperties;
}

const SendOrder = () => {
  return (
    <div className={css.ConfirmationSection}>
      <ol className={css.NumberedList}>
        <li className={css.NumberedListElement}>
          <p className={css.ListText}>
            Du må forhåndsbetale bestillingen din med vipps.
          </p>
        </li>
        <li className={css.NumberedListElement}>
          <p className={css.ListText}>
            Du får to eposter: En bekreftelse på bestilling, og en med hentekode
            som du skriver på pakken du skal sende.
          </p>
        </li>
        <li className={css.NumberedListElement}>
          <p className={css.ListText}>
            Skulle det dukke opp noe eller reparasjonen blir dyrere enn
            forventet vil vi ta kontakt med deg, og du vil få mulighet til å
            kansellere bestillingen.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default SendOrder;
