import React from "react";
import css from "../../styles/SectionOrderText.module.scss";

export interface Props {
  orderCategory: string;
  ordername: string;
  price?: number;
  onClickReduce?: () => void;
  onClickAdd?: () => void;
}

const SectionOrderText: React.FC<Props> = function ({
  orderCategory,
  ordername,
  price,
  onClickReduce,
  onClickAdd,
}) {
  return (
    <div>
      <div className={css.SectionOrderText}>
        <span>
          <p>
            <span className={css.OrderCategory}>{orderCategory}:</span> 1{" "}
            {ordername}
          </p>
          <p style={{ marginTop: "0.5vw", color: "rgba(0, 0, 0, 0.6)" }}>
            {price}.-
          </p>
        </span>
        <div className={css.OrderButtons}>
          <button className={css.ReduceButton} onClick={onClickReduce}>
            <img src="images/reduce-amount.svg" alt="reduce-amount" />
          </button>
          <button className={css.AddButton} onClick={onClickAdd}>
            <img src="images/add-amount.svg" alt="add-amount" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SectionOrderText;
