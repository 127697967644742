import React, { useEffect, useState } from "react";
import css from "../styles/Footer.module.scss";

interface FooterProps {
  path: string;
}
const Footer: React.FC<FooterProps> = ({ path }) => {
  const [footerText, setFooterText] = useState<string>("");
  const [imgSource, setImgSource] = useState<string>("");

  useEffect(() => {
    if (path === "/confirmation") {
      setFooterText("Takk for at du velger å fikse, fremfor å kjøpe nytt!");
      setImgSource("images/illustration/confirmation-waiting.svg");
    } else {
      setFooterText("Vårt oppdrag er å øke reparasjonsgraden i samfunnet");
      setImgSource("images/illustration/footer-woman-cat.svg");
    }
  }, [path]);

  return (
    <footer className={css.Footer}>
      <div className={css.ContactInfo}>
        <a href="http://fikse.co/">
          <img src={imgSource} alt="footer-illustration" />
          <p>Fikse</p>
        </a>
        <div style={{ opacity: "60%" }}>
          <p>
            hei@fikse.co <br />
            484 50 390
          </p>
        </div>
        <p className={css.Goal}>{footerText}</p>
      </div>
    </footer>
  );
};

export default Footer;
