/* eslint-disable*/
import React, { useEffect, useContext, useState } from "react";
import css from "../../styles/OrderSummary.module.scss";
import SectionOrderText from "./SectionOrderText";
import uuid from "react-uuid";
import { OrderStateContext } from "../../context/OrderStateContext";


import {
  OrderContext,
  OrderContextType,
  Order,
} from "../../context/OrderContext";

export const style = {};

export interface Props {
  orders: Order[];
  setOpenOrders: (value: boolean) => void;
  setOpenCheckout: (value: boolean) => void;
  sumPriceOrders: () => number;
}

const OrderSummary: React.FC<Props> = ({
  orders,
  setOpenOrders,
  setOpenCheckout,
  sumPriceOrders,
}) => {
  const { setCheck, storage, setStorage } = useContext(
    OrderContext
  ) as OrderContextType;

  const { setOrderState } = useContext(OrderStateContext);

  const handleInput = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    id: string
  ) => {
    const { value } = e.target;
    const newStorage = [...storage];
    const index = newStorage.findIndex((obj) => obj.id == id);
    newStorage[index].description = value;
    setStorage(newStorage);
  };

  useEffect(() => {
    if (storage.length === 0) {
      setCheck(false);
      setOpenOrders(false);
    }

    localStorage.setItem("currentOrder", JSON.stringify(storage));
  }, [storage]);

  function removeOrder(id: string) {
    const newStorage = [...storage];
    const index = newStorage.findIndex((obj) => obj.id == id);
    newStorage.splice(index, 1);
    setStorage(newStorage);
  }

  function addOrder(id: string) {
    const index = storage.findIndex((obj) => obj.id == id);

    const item = {
      mainCategory: storage[index].mainCategory,
      category: storage[index].category,
      product: storage[index].product,
      price: storage[index].price,
      description: "",
      id: uuid(),
      eligibleForExpress: storage[index].eligibleForExpress,
    };

    const newStorage = [...storage];
    newStorage.splice(index, 0, item);
    setStorage(newStorage);
  }

  const price = sumPriceOrders();

  const closeBasket = () => {
    setOpenOrders(false);
    setOrderState(false);
  };

  const [showValidationMessage, setShowValidationMessage] = useState(false);

  const isDescriptionValid = () => {
    return storage.every((item) => item.description.trim().length >= 2);
  };

  const handleCheckoutClick = () => {
    if (isDescriptionValid()) {
      setOpenOrders(false);
      setOpenCheckout(true);
    } else {
      setShowValidationMessage(true);
      setTimeout(() => setShowValidationMessage(false), 3000); // Meldingen forsvinner etter 3 sekunder
    }
  };
  

  return (
    <div className={css.OrderSummary}>
      <div className={css.WrapperTitle}>
        <h3>Kurv</h3>
        <button className={css.CloseButton} onClick={() => closeBasket()}>
          {" "}
          <img
            onClick={() => setOpenOrders(false)}
            src={"images/exit-cross.svg"}
            alt="cross-exit"
            className={css.ExitCross}
          />
        </button>
      </div>
      <div className={css.SummaryContainer}>
        {orders.map((order: Order, key: number) => (
          <div key={key}>
            <div className={css.Orders}>
              <SectionOrderText
                orderCategory={order.category}
                ordername={order.product}
                price={order.price}
                onClickReduce={() => removeOrder(order.id)}
                onClickAdd={() => addOrder(order.id)}
              />
            </div>
            <textarea
              className={css.input}
              rows={1}
              placeholder="Beskriv det som skal fikses, f eks. antall cm, farge på glidelås."
              value={order.description}
              onChange={(e) => handleInput(e, order.id)}
              required
            />
          </div>
        ))}

        <div className={css.TotalCost}>
          <p>
            Totalt{" "}
            <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>inkl. mva</span>
          </p>
          <p>{price}.-</p>
        </div>
        {showValidationMessage && (
        <div className={css.ValidationMessage}>
          Vennligst fyll ut beskrivelsesfeltet for alle ordre.
        </div>
      )}
      <div className={css.ToCheckout}>
        <button
          onClick={handleCheckoutClick}
        >
            <p>Videre til bestilling</p>
            <img
              src="images/right-faced-vector.svg"
              alt="right-faced-vector"
              className={css.Icon}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
