/* eslint-disable */
import React, { useState, useContext, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import css from "../../styles/CheckoutForm.module.scss";
import {
  OrderContext,
  OrderContextType,
  Order,
} from "../../context/OrderContext";
import { DeliveryContext } from "../../context/DeliveryContext";
import { OrderStateContext } from "../../context/OrderStateContext";
import { firestore, functions } from "../../firebaseConfig"; 

export interface Props {
  setOpen: (value: boolean) => void;
  sumPriceOrders: () => number;
}

const CheckoutForm: React.FC<Props> = function ({ setOpen, sumPriceOrders }) {
  const navigate = useNavigate();
  const { setCheck, storage, setStorage } = useContext(
    OrderContext
  ) as OrderContextType;
  const checkBunad = storage.filter((i) => i.category === "Bunad");
  const [text, setText] = useState("Send bestilling og betal");
  const priceOrders = sumPriceOrders();
  const [price] = useState<number>(priceOrders);
  const [expressCost, setExpressCost] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [deliverTo, setDeliverTo] = useState<boolean>(false);
  const [deliverHome, setDeliverHome] = useState<boolean>(false);
  const [disableExpress, setDisableExpress] = useState<boolean>(false);
  const [disableOption, setDisableOption] = useState<boolean>(false);
  const [deliveryCost, setDeliveryCost] = useState<number>(0);
  const [summaryText, setSummaryText] = useState<string | string[]>([
    "Ferdig innen 10 dager",
    "fra vi mottar pakken",
  ]);
  const [showAddressFields, setShowAddressFields] = useState<boolean>(false);
  const [contactInformation, setContactInformation] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    street: "",
    streetNumber: "",
    floor: "",
    postCode: "",
    city: "",
    value: "",
    time: "",
  });
  const [saveContactInformation, setSaveContactInformation] =
    useState<boolean>(false);
  const [emailEnv, setEmailEnv] = useState<string>(
    process.env.REACT_APP_ORDER_DELIVERY_TEMPLATE_ID as string
  );
  const [companyCode, setCompanyCode] = useState<string>("");
  const { setDeliveryData } = useContext(DeliveryContext);
  const { setOrderState } = useContext(OrderStateContext);

  const [dropOffLocation, setDropOffLocation] = useState<string>("");
  const [pickUpLocation, setPickUpLocation] = useState<string>("");

  const sendOrder = useRef<HTMLInputElement>(null);
  const dropOffOrder = useRef<HTMLInputElement>(null);
  const receiveOrder = useRef<HTMLInputElement>(null);
  const pickUpOrder = useRef<HTMLInputElement>(null);
  const rememberAddress = useRef<HTMLInputElement>(null);
  const expressOrder = useRef<HTMLInputElement>(null);
  const form = useRef(null);
  const companyOrder = useRef<HTMLInputElement>(null);

  const deliveryPrice = 89;
  const expressPrice = 149*storage.length;
  console.log(storage.length)

  const submitButton = document.getElementById("submit-button");
  const formElement = document.getElementById("checkOutForm");

  const [locations, setLocations] = useState<string[]>(["Velg sted"]);
  const [locationError, setLocationError] = useState<string>("");

  const [customers, setCustomers] = useState<
  { name: string; phoneNumber: string; email: string; orders: Order[] }[]
>([]);

const addCustomer = () => {
  setCustomers([...customers, { name: "", phoneNumber: "", email: "", orders: [] }]);
};

const updateCustomerInfo = (index: number, field: string, value: string) => {
  const updatedCustomers = [...customers];
  updatedCustomers[index] = { ...updatedCustomers[index], [field]: value };
  setCustomers(updatedCustomers);
};

const updateCustomerProducts = (index: number, selectedOrder: Order) => {
  const updatedCustomers = [...customers];
  const customerOrders = updatedCustomers[index].orders || [];

  if (isProductSelectedByOther(selectedOrder, index)) {
    return;
  }

  if (customerOrders.find(order => order.product === selectedOrder.product)) {
    updatedCustomers[index].orders = customerOrders.filter(order => order.product !== selectedOrder.product);
  } else {
    updatedCustomers[index].orders = [...customerOrders, selectedOrder];
  }

  setCustomers(updatedCustomers);
  console.log(updatedCustomers)
};

const isProductSelectedByOther = (selectedOrder: Order, customerIndex: number) => {
  return customers.some((cust, index) => 
    index !== customerIndex && cust.orders.some(order => order.product === selectedOrder.product)
  );
};

  useEffect(() => {
    const fetchLocations = async () => {
        try {
            const locationsDoc = await firestore.collection("dynamicPages").doc("locations").get();
            if (locationsDoc.exists) {
                const data = locationsDoc.data();
                if (data && data.locationsList) {
                  const updatedLocations = ["Velg sted", ...data.locationsList];
                  setLocations(updatedLocations);
                  if (pickUpOrder.current && dropOffOrder.current) {
                    pickUpOrder.current.checked = true;
                    dropOffOrder.current.checked = true;
                    handleRadioButtons();
                  }
                }
            }
        } catch (error) {
            console.error("Error fetching locations:", error);
        }
    };

    fetchLocations();
}, []);

  submitButton?.addEventListener("click", function () {
    // @ts-expect-error
    formElement.className = "submitted";
  });

  const DropDownMenu = ({
    label,
    options,
    selectedValue,
    onChange,
  }: {
    label: string;
    options: string[];
    selectedValue: string;
    onChange: (value: string) => void;
  }) => {
    return (
      <div className={`${css.DropDownContainer}`}>
        <label className={css.DropDownLabel}>{label}</label>
        <select
          className={css.DropDownSelect}
          value={selectedValue}
          onChange={(e) => onChange(e.target.value)}
        >
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const handleRadioButtons = () => {
    if (companyCode) {
      // Når en bedriftskode er lagt inn
      setDeliverHome(false);
      setDeliverTo(false);
      setDisableExpress(false);
      if (pickUpOrder.current && dropOffOrder.current) {
        pickUpOrder.current.checked = true;
        dropOffOrder.current.checked = true;
        
        if (sendOrder.current && receiveOrder.current) {
          sendOrder.current.disabled = true;
          receiveOrder.current.disabled = true;
        }
  
        setDeliveryCost(0);
        setShowAddressFields(false);
      }}
      else
      {
    if (!companyCode) {
      if (sendOrder.current && receiveOrder.current) {
        sendOrder.current.disabled = false;
        receiveOrder.current.disabled = false;
      }
      sendOrder.current?.checked
        ? setEmailEnv(
            process.env.REACT_APP_ORDER_DELIVERY_TEMPLATE_ID as string
          )
        : setEmailEnv(process.env.REACT_APP_ORDER_TEMPLATE_ID as string);
    }

    if (receiveOrder.current && sendOrder.current) {
      setDeliverHome(receiveOrder.current.checked);
      setDeliverTo(sendOrder.current.checked);
    }

    if (sendOrder.current?.checked && receiveOrder.current?.checked) {
      setDeliveryCost(deliveryPrice * 2);
      setShowAddressFields(true);
      return;
    }

    if (sendOrder.current?.checked || receiveOrder.current?.checked) {
      setDeliveryCost(deliveryPrice);
      setShowAddressFields(true);
      setDisableExpress(true);
    }

    if (pickUpOrder.current?.checked && dropOffOrder.current?.checked) {
      setDeliveryCost(0);
      setShowAddressFields(false);
      setDisableExpress(false);
    }

    if (pickUpOrder.current?.checked && pickUpLocation == "") {
      setPickUpLocation(locations[0]); // Setter default hentested til UFF
    }
  
    if (dropOffOrder.current?.checked && dropOffLocation == "") {
      setDropOffLocation(locations[0]); // Setter default leveringssted til UFF

    }
    if (dropOffLocation !== "LUGA Oslo"){
      setDisableExpress(true);
    }
  }};

  useEffect(() => {
      handleRadioButtons();
  }, [companyCode, dropOffLocation]);

  useEffect(() => {
    if (Array.isArray(storage) && storage.length === 0 && text != "Sender...") {
      alert("Det har skjedd en feil. Prøv igjen.");
      location.reload();
    }
  }, [storage]);

  useEffect(() => {
    const obj = localStorage.getItem("contactInformation");
    const checkExpressOption = storage.filter(
      (product) => product.eligibleForExpress === false
    );

    if (obj) {
      const parsedObj = JSON.parse(obj);
      if (parsedObj) {
        setContactInformation(JSON.parse(obj));
        setSaveContactInformation(true);
      }
    }

    if (checkBunad.length) {
      setDisableOption(true);
      setDeliverTo(false);
      setDeliverHome(false);
    }

    const savedCompanyCode = localStorage.getItem("companyCode");
    if (savedCompanyCode) {
      const parsedObj = JSON.parse(savedCompanyCode);
      if (parsedObj) {
        setCompanyCode(parsedObj);
        setEmailEnv(process.env.REACT_APP_ORDER_RETAIL_TEMPLATE_ID as string);
      }
    }

    (checkExpressOption.length || checkBunad.length) && setDisableExpress(true);
  }, []);

  useEffect(() => {
    setTotalPrice(price + expressCost + deliveryCost);
  }, [price, expressCost, deliveryCost]);

  const handleExpress = () => {
    if (expressOrder.current?.checked) {
      setExpressCost(expressPrice);
      setDeliveryCost(0);
      setSummaryText("Ferdig samme dag som levering");
      setDisableOption(true);
      setDeliverTo(false);
      setDeliverHome(false);
      setShowAddressFields(false);

      if (
        sendOrder.current &&
        receiveOrder.current &&
        pickUpOrder.current &&
        dropOffOrder.current
      ) {
        sendOrder.current.checked = false;
        receiveOrder.current.checked = false;
        pickUpOrder.current.checked = true;
        dropOffOrder.current.checked = true;
      }
    } else {
      setExpressCost(0);
      setDisableOption(false);
      setSummaryText(["Ferdig innen 10 dager", "fra vi mottar pakken"]);
    }
  };

  const stringToHTML = (str: string) => {
    const dom = document.createElement("p");
    dom.innerHTML = str;
    return dom.outerHTML;
  };

  const ordersToString = () => {
    let str = "";
    storage.map((order: Order) => {
      const toHTML =
        `<b>1 ${order.product}` +
        " " +
        `${order.price}` +
        " kr " +
        "</b><br>" +
        `${order.description}`;
      str += stringToHTML(toHTML);
    });
    return str;
  };

  useEffect(() => {
    if (companyCode.length) {
      setEmailEnv(process.env.REACT_APP_ORDER_RETAIL_TEMPLATE_ID as string);
      setExpressCost(0);
      setDeliveryCost(0);
      setDisableOption(false);
      setDeliverTo(false);
      setDeliverHome(false);
      setShowAddressFields(false);
    }
    if (companyCode === '') {
      setCustomers([]);
    }
  }, [companyCode]);


  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    setLocationError("");
    
    if (dropOffOrder.current?.checked && (dropOffLocation === "Velg sted" || dropOffLocation === "")) {
      setLocationError("Vennligst velg et gyldig leveringssted");
      return;
    }

    if (pickUpOrder.current?.checked && (pickUpLocation === "Velg sted" || pickUpLocation === "")) {
      setLocationError("Vennligst velg et gyldig hentested");
      return;
    }

    setText("Sender...");

    setDeliveryData({
      totalPrice: totalPrice,
      isSent: deliverTo || deliverHome ? true : false,
      isCompanyOrder: companyCode.length !== 0,
    });


    
    if (companyOrder) {
      localStorage.setItem("companyCode", JSON.stringify(companyCode));
    }
    if (deliverTo || deliverHome) {
      if (rememberAddress.current?.checked) {
        localStorage.setItem(
          "contactInformation",
          JSON.stringify(contactInformation)
        );
      } else {
        localStorage.removeItem("contactInformation");
      }
    }

    //Gathering all relevant orderdata for backend-call

      const orderData = {
        dateOrdered: new Date().toISOString().split('T')[0],
        companyCode: companyCode, //Code in checkout
        officeCode: localStorage.getItem('officeCode'), //Kontoravtale code
        order: storage,
        deliveryTo: "default",
        deliveryFrom: "default",
        express: expressOrder.current?.checked,
        price: totalPrice,
        paid: false,
        contactInformation: contactInformation,
        receiveOrder: receiveOrder.current?.checked,
        sendOrder: sendOrder.current?.checked,
        pickUpOrder: pickUpOrder.current?.checked,
        dropOffOrder: dropOffOrder.current?.checked,
        deliveryPrice: deliveryPrice,
        expressPrice: expressPrice,
        dropOffLocation: dropOffLocation,
        pickUpLocation: pickUpLocation,
        customers: customers,
      };


      if (companyCode) {

         // Legg til kode for å sende en forespørsel til Firebase Functions
const firebaseFunction = functions.httpsCallable("createCompanyCheckout");
 firebaseFunction(orderData)
   .then((result: any) => {
     console.log("Firebase Function response:", result);
     setCheck(false);
     setStorage([]);
     localStorage.removeItem("currentOrder");
     setOrderState(false);
     navigate('/confirmation', { replace: true });
   })

      }else{

        // Legg til kode for å sende en forespørsel til Firebase Functions
 const firebaseFunction = functions.httpsCallable("createVippsCheckout");
 firebaseFunction(orderData)
   .then((result: any) => {
     console.log("Firebase Function response:", result);
     setCheck(false);
     setStorage([]);
     localStorage.removeItem("currentOrder");
     setOrderState(false);
     window.location.href = result.data.url;
   })

      }

  };

  const closeModal = () => {
    setOpen(false);
    setOrderState(false);
  };

  const removeCustomer = (index: number) => {
    const updatedCustomers = [...customers];
    updatedCustomers.splice(index, 1);
    setCustomers(updatedCustomers);
  };

  return (
    <div className={css.CheckoutForm}>
      <div className={css.WrapperTitle}>
        <h3>Bestilling</h3>
        <button className={css.CloseButton} onClick={() => closeModal()}>
          <img
            src={"images/exit-cross.svg"}
            alt="cross-exit"
            className={css.ExitCross}
          />
        </button>
      </div>

      <form ref={form} onSubmit={handleSubmit} id="checkOutForm">
      {!localStorage.getItem('officeCode')?
        <>
        <div className={css.Description}>
          <p className={css.Title}>Er du bedrift?</p>
          <p className={css.Text}>
            Skriv bedriftskoden din her. Kun for butikker vi allerede har
            samarbeid med.
          </p>
        </div>
        <div className={css.DiscountCode}>
          <label htmlFor="companycode" />
          <input
            type="text"
            id="companycode"
            name="companycode"
            placeholder="Hva er bedriftskoden din?"
            ref={companyOrder}
            value={companyCode}
            onChange={(e) => setCompanyCode(e.target.value)}
          ></input>
        </div>   
        </>
        :""}

        {!companyCode.length && (
          <>
            <div className={css.Description}>
              <p className={css.Title}>Levering til Fikse </p>
              <p className={css.Text}>
                Har du spesielle ønsker, eller vil at vi tar mål, må du levere
                til oss.{" "}
                <a href="https://blogg-fikse.squarespace.com/her-er-vi">
                  Finn studio her
                </a>
              </p>
            </div>
            <div className={css.Delivery}>
              <div className={css.DeliveryItem}>
                <input
                  type="radio"
                  id="own-delivery"
                  name="delivery"
                  value="Leverer selv"
                  ref={dropOffOrder}
                  onChange={handleRadioButtons}
                />
                <label htmlFor="own-delivery">
                <span style={{ display: 'inline-block', width: '90%' }}>Jeg leverer selv </span>
                  {dropOffOrder.current?.checked && (
  <DropDownMenu
    label=""
    options={locations}
    selectedValue={dropOffLocation}
    onChange={(value) => {
      setDropOffLocation(value);
      setPickUpLocation(value);
    }}
  />
)} 
<span>0.-</span>
                </label>
        
              </div>

              <div className={css.DeliveryItem}>
                <input
                  type="radio"
                  id="send"
                  name="delivery"
                  value="Sendes med bud "
                  ref={sendOrder}
                  onChange={handleRadioButtons}
                  disabled={disableOption}
                />
                <label htmlFor="send">
                  Send med bud <span>+ 89.-</span>
                </label>
              </div>
            </div>
            <div className={css.Description}>
              <p className={css.Title}>Når plagget er ferdig</p>
              <p className={css.Text}>Hvordan vil du få tilbake plagget?</p>
            </div>
            <div className={css.Delivery}>
              <div className={css.DeliveryItem}>
                <input
                  type="radio"
                  id="pick-up"
                  name="return"
                  value="Henter selv"

                  ref={pickUpOrder}
                  onChange={handleRadioButtons}
                />
                <label htmlFor="pick-up">
                <span style={{ display: 'inline-block', width: '90%' }}>Jeg henter selv </span>

                  {pickUpOrder.current?.checked && (

  <DropDownMenu
  label=""
  options={locations}
  selectedValue={pickUpLocation}
  onChange={(value) => {setPickUpLocation(value)}}
/>
)}
                
<span>0.-</span>
                </label>
            
              </div>
              <div className={css.DeliveryItem}>
                <input
                  type="radio"
                  id="home-delivery"
                  name="return"
                  value="Hjemlevering med bud"
                  ref={receiveOrder}
                  onChange={handleRadioButtons}
                  disabled={disableOption}
                />
                <label htmlFor="home-delivery">
                  Hjemlevering med bud <span>+ 89.-</span>
                </label>
              </div>
            </div>{" "}
            </>
            )}
            <div className={css.Description}>
              <p className={css.Title}>Haster det?</p>
              <p className={css.Text}>
                Vi kan prioritere din bestilling og fikse det for deg innen ett
                døgn dersom du leverer på LUGA Oslo og henter selv.
              </p>
            </div>
            <div className={css.Express}>
              <input
                type="checkbox"
                id="express"
                name="express"
                ref={expressOrder}
                onClick={handleExpress}
                disabled={disableExpress}
              />
              <label htmlFor="express">
                Legg til ekspress <span>149.- per plagg</span>
              </label>
            </div>

        <div className={css.Description}>
          <p className={css.Title}>Hva er din kontaktinformasjon? </p>
        </div>
        <label htmlFor="name" />
        <input
          type="text"
          value={contactInformation.name}
          id="name"
          placeholder="Navn Navnesen"
          name="name"
          required
          onChange={(e) =>
            setContactInformation({
              ...contactInformation,
              name: e.target.value,
            })
          }
        ></input>
        <label htmlFor="phonenumber" />
        <input
          type="tel"
          value={contactInformation.phoneNumber}
          id="phonenumber"
          name="phonenumber"
          title="Må være åtte sifre"
          pattern="[0-9]{8}"
          placeholder="Telefonnummer?"
          required
          onChange={(e) =>
            setContactInformation({
              ...contactInformation,
              phoneNumber: e.target.value,
            })
          }
        ></input>
        <label htmlFor="email" />
        <input
          type="email"
          value={contactInformation.email}
          id="email"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          title="Ugyldig adress"
          placeholder="Hva er e-postaddressen din?"
          name="email"
          onChange={(e) =>
            setContactInformation({
              ...contactInformation,
              email: e.target.value,
            })
          }
          required
        ></input>
{companyCode && (
  <>
    {customers.map((customer, index) => (
      <div key={index}>
        <div style={{ display: 'flex', alignItems: 'center', margin: "20px" }}>
          <p className={css.Title}>Kunde {index + 1} - Velg produkter</p>
          <button 
            onClick={() => removeCustomer(index)}
            className={css.removeButton}
          >
            X
          </button>
        </div>
        <div className={css.productContainer}>
        {storage.map((order, orderIndex) => (
          <div 
          key={orderIndex} 
          className={`${css.productBox} ${customer.orders.find(o => o.product === order.product) ? css.selected : ''}`}
          onClick={() => updateCustomerProducts(index, order)}
        >
          {order.category} - {order.product}
        </div>
        ))}
      </div>
        <input
          type="text"
          placeholder="Navn Kundesen"
          value={customer.name}
          onChange={(e) =>
            updateCustomerInfo(index, "name", e.target.value)
          }
        />
        <input
          type="tel"
          placeholder="Telefonnummer?"
          value={customer.phoneNumber}
          onChange={(e) =>
            updateCustomerInfo(index, "phoneNumber", e.target.value)
          }
        />
        <input
          type="email"
          placeholder="Hva er e-postadressen til kunden?"
          value={customer.email}
          onChange={(e) =>
            updateCustomerInfo(index, "email", e.target.value)
          }
        />
  </div>
))}
    <button onClick={addCustomer} className={css.addButton}>+ Legg til kontaktinformasjon til kunde</button>
  </>
)}
        {!companyCode.length && showAddressFields && (
          <>
            <label htmlFor="street" />
            <input
              name="street"
              type="text"
              value={contactInformation.street}
              id="street"
              placeholder="Adressen din?"
              onChange={(e) =>
                setContactInformation({
                  ...contactInformation,
                  street: e.target.value,
                })
              }
              required
            ></input>
            <label htmlFor="street-number" />
            <input
              type="text"
              name="streetnumber"
              value={contactInformation.streetNumber}
              id="streetnumber"
              placeholder="Nummer"
              onChange={(e) =>
                setContactInformation({
                  ...contactInformation,
                  streetNumber: e.target.value,
                })
              }
              required
            ></input>
            <label htmlFor="floor" />

            <input
              type="text"
              id="floor"
              value={contactInformation.floor}
              name="floor"
              placeholder="Etasje"
              onChange={(e) =>
                setContactInformation({
                  ...contactInformation,
                  floor: e.target.value,
                })
              }
            ></input>
            <label htmlFor="postcode" />
            <input
              name="postcode"
              type="text"
              value={contactInformation.postCode}
              id="postcode"
              title="Må være fire tall"
              pattern="[0-9]{4}"
              placeholder="Postnummer"
              onChange={(e) =>
                setContactInformation({
                  ...contactInformation,
                  postCode: e.target.value,
                })
              }
              required
            ></input>
            <label htmlFor="city" />
            <input
              type="text"
              name="city"
              id="city"
              value={contactInformation.city}
              placeholder="Sted"
              onChange={(e) =>
                setContactInformation({
                  ...contactInformation,
                  city: e.target.value,
                })
              }
              required
            ></input>
            <label htmlFor="value" />
            <input
              type="text"
              name="value"
              id="value"
              value={contactInformation.value}
              placeholder="Hva er det du sender verdt?"
              onChange={(e) =>
                setContactInformation({
                  ...contactInformation,
                  value: e.target.value,
                })
              }
              required
            ></input>
            <label htmlFor="drop-off-time" />
            <input
              type="text"
              name="dropofftime"
              id="drop-off-time"
              value={contactInformation.time}
              pattern="^\d{2}.\d{2}$"
              title="Ugyldig tid Eks. 23.00"
              onChange={(e) =>
                setContactInformation({
                  ...contactInformation,
                  time: e.target.value,
                })
              }
              placeholder="Jeg legger ut pakken(før kl23): dd.mm"
            ></input>
            <div className={css.CheckBoxContainer}>
              <p>Husk denne informasjonen til neste bestilling</p>
              <label htmlFor="remember-me">
                <input
                  type="checkbox"
                  id="remember-me"
                  name="remember-me"
                  ref={rememberAddress}
                  defaultChecked={saveContactInformation}
                />
                <span className={css.Checkmark}></span>
              </label>
            </div>
          </>
        )}

        <div className={css.Terms}>
          <p className={css.Title}>Vilkår </p>
        </div>
        <div className={css.CheckBoxContainer}>
          <p>
            Jeg godtar{" "}
            <a href="https://blogg-fikse.squarespace.com/kjopsvilkar">
              kjøpsvilkårene
            </a>{" "}
            og at informasjon om meg og min bestilling lagres
          </p>
          <label htmlFor="terms">
            <input type="checkbox" id="terms" name="terms" required />
            <span className={css.Checkmark}></span>
          </label>
        </div>
        <div className={css.News}>
          <p>
            Ja, jeg vil få relevante nyheter og oppdateringer fra Fikse. Vi
            lover å ikke spamme deg.
          </p>
          <label htmlFor="news">
            <input type="checkbox" id="news" name="news" />
            <span className={css.Checkmark}></span>
          </label>
        </div>
        <div
          className={css.Description}
          style={{ borderBottom: "1px dashed black" }}
        >
          <p className={css.Title}>Bestilling: Privatkunder betaler med Vipps </p>
        </div>
        <ul className={css.OrderList}>
          {storage.map((order: Order, key: number) => (
            <li className={css.Order} key={key}>
              <p>
                <span className={css.OrderCategory}>{order.category}:</span>1{" "}
                {order.product}
              </p>
              <p>{order.price}.-</p>
            </li>
          ))}
        </ul>
        {expressCost !== 0 && (
          <div className={css.Order}>
            <p>Ekspress på {storage.length} plagg</p>
            <p>{149*storage.length}.-</p>
          </div>
        )}
        {deliverTo && (
          <div className={css.Order}>
            <p>Send med bud</p>
            <p>89.-</p>
          </div>
        )}
        {deliverHome && (
          <div className={css.Order}>
            <p>Hjemlevering med bud</p>
            <p>89.-</p>
          </div>
        )}
        <div className={css.TotalCost}>
          <p>
            Totalt{" "}
            <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>inkl. mva</span>
          </p>
          <p>{totalPrice}.-</p>
        </div>
        <div className={css.ImageContainer}>
          <img
            src="images/illustration/checkout-employee.svg"
            alt="checkout-employee"
          />
        </div>
        <div className={css.DateTimeContainer}>
          <p style={{ color: "rgba(0, 0, 0, 0.6)" }}>
            {Array.isArray(summaryText)
              ? summaryText.map((p, i) => {
                  return <span key={i}>{p}</span>;
                })
              : summaryText}
          </p>
        </div>
        <input type="hidden" name="totalprice" defaultValue={totalPrice} />
        <input type="hidden" name="order" defaultValue={ordersToString()} />
        {locationError && <p className="error">{locationError}</p>}
        <input
        id="submit-button"
        className={css.SendOrder}
        type="submit"
        value={text}
        disabled={text === "Sender..."}
      ></input>
      </form>
    </div>
  );
};

export default CheckoutForm;
