import { createContext, useState } from "react";

type RenderHomeComponentsContextType = {
  renderHomeComponents: boolean;
  setRenderHomeComponents: (renderHomeComponents: boolean) => void;
};
const RenderHomeComponentsContext =
  createContext<RenderHomeComponentsContextType>({
    renderHomeComponents: true,
    setRenderHomeComponents: () => {},
  });

interface Props {
  children: React.ReactNode;
}

const RenderHomeComponentsContextProvider: React.FC<Props> = ({
  children,
}: Props) => {
  const [renderHomeComponents, setRenderHomeComponents] =
    useState<boolean>(true);

  return (
    <RenderHomeComponentsContext.Provider
      value={{ renderHomeComponents, setRenderHomeComponents }}
    >
      {children}
    </RenderHomeComponentsContext.Provider>
  );
};

export { RenderHomeComponentsContext, RenderHomeComponentsContextProvider };
