import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { firestore } from "../firebaseConfig";
import css from "../styles/AdminHome.module.scss";
/*eslint-disable*/

const db = firestore;

interface Order {
  id: string;
  companyCode: string;
  officeCode: string;
  contactInformation: {
    city: string;
    email: string;
    floor: string;
    name: string;
    phoneNumber: string;
    postCode: string;
    street: string;
    streetNumber: string;
    time: string;
    value: string;
  };
  dateOrdered: string;
  deliveryFrom: string;
  deliveryPrice: number;
  deliveryTo: string;
  dropOffOrder: boolean;
  express: boolean;
  expressPrice: number;
  order: {
    category: string;
    description: string;
    eligibleForExpress: boolean;
    id: string;
    price: number;
    product: string;
  }[];
  paid: boolean;
  pickUpOrder: boolean;
  price: number;
  receiveOrder: boolean;
  sendOrder: boolean;
  pickUpLocation: string;
  dropOffLocation: string;
}

// Data structure for the new "Bunad" category
const bunadData = [
    {
      name: "Reparasjon av bunad",
      product: [
        { type: "Lapping", smallRepair: 399, bigRepair: 799 },
        { type: "Påsying av veskelås", smallRepair: 999, bigRepair: 1399 },
        { type: "Hemp og knapp", smallRepair: 349, bigRepair: 599 },
        { type: "Søm", smallRepair: 399, bigRepair: 699 },
        { type: "Puss av bunadssølv", smallRepair: 549, bigRepair: 1499 },
        { type: "Reparasjon av bunadssølv", smallRepair: 1399, bigRepair: 2399 },
      ],
    },
    {
      name: "Rens av bunad",
      product: [
        { type: "Bunad skjorte/forkle", smallRepair: 399, bigRepair: 499 },
        { type: "Bunad underdel", smallRepair: 549, bigRepair: 899 },
        { type: "Bunad overdel", smallRepair: 399, bigRepair: 699 },
        { type: "Hel bunad", smallRepair: 899, bigRepair: 1599 },
      ],
    },
    {
      name: "Omsøm av bunad",
      product: [
        { type: "Skulderbredde", smallRepair: 4499, bigRepair: 5999 },
        { type: "Sy inn eller legge ut", smallRepair: 5299, bigRepair: 6999 },
        { type: "Bukse eller vest", smallRepair: 3299, bigRepair: 4999 },
        { type: "Legge opp eller ned", smallRepair: 999, bigRepair: 2499 },
      ],
    },
  ];


// Function to add new category data to Firestore under the specified collection and document
const addCategoryToDatabase = async (categoryName: string, categoryData: any) => {
  try {
    // Reference to the specific document where the category should be added
    const documentRef = db.collection('dynamicPages').doc('products');

    // Update the document with the new category data
    // This creates a new field in the document for the category, or updates it if it already exists
    await documentRef.set({ [categoryName]: categoryData }, { merge: true });

    console.log(`${categoryName} category added successfully`);
  } catch (error) {
    console.error(`Error adding ${categoryName} category to database:`, error);
  }
};

const AdminHome: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [orders, setOrders] = useState<Order[]>([]);
    const [uniqueCompanyCodes, setUniqueCompanyCodes] = useState<string[]>([]);
    const [selectedCompanyCode, setSelectedCompanyCode] = useState<string>("");
    const [selectedRepairType, setSelectedRepairType] = useState<string>("");
    const [selectedCategory, setSelectedCategory] = useState<string>("");
    const [selectedDelivery, setSelectedDelivery] = useState<string>("");
    const [selectedExpressStatus, setSelectedExpressStatus] = useState("");
    const [selectedPickUpLocation, setSelectedPickUpLocation] = useState("");
    const [selectedDropOffLocation, setSelectedDropOffLocation] = useState("");
const [uniquePickUpLocations, setUniquePickUpLocations] = useState<string[]>([]);
const [uniqueDropOffLocations, setUniqueDropOffLocations] = useState<string[]>([]);
  
    const [uniqueRepairTypes, setUniqueRepairTypes] = useState<string[]>([]);
    const [uniqueCategories, setUniqueCategories] = useState<string[]>([]);
    const [uniqueDeliveries, setUniqueDeliveries] = useState<string[]>([]);
     const [filterByCompanyCode, setFilterByCompanyCode] = useState<"yes" | "no" | "">("");
     const [selectedMonth, setSelectedMonth] = useState("");

     const monthNames = ["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Desember"];
const currentYear = new Date().getFullYear();
const yearRange = Array.from({length: 2}, (_, i) => currentYear - i); // Siste 5 år inkludert inneværende år

const [selectedYear, setSelectedYear] = useState("");
  
    const [allOrders, setAllOrders] = useState<Order[]>([]); // Ny state-variabel for å lagre alle bestillinger

    useEffect(() => {
      db.collection("orders").get().then(querySnapshot => {
        const data = querySnapshot.docs.map(doc => {
          const orderData = doc.data() as Order;
          orderData.companyCode = orderData.companyCode.toUpperCase();
          return {
            ...orderData,
            id: doc.id,
          };
        });
    
        // Filtrerer ut ordre med companyCode == "" og paid == false
        const filteredData = data.filter(order => !(order.companyCode === "" && order.paid === false));
    
        setAllOrders(filteredData);
    
        const companyCodes = Array.from(new Set(data.map(order => order.companyCode).filter(code => code))).sort();
        const repairTypes = Array.from(new Set(data.map(order => order.order[0]?.product).filter(type => type)));
        const categories = Array.from(new Set(data.map(order => order.order[0]?.category).filter(category => category)));
        const deliveries = Array.from(new Set(data.map(order => {
          if (order.dropOffOrder) return "DropOff";
          if (order.pickUpOrder) return "PickUp";
          if (order.sendOrder) return "Send";
          return "Unknown";
        }).filter(delivery => delivery !== "Unknown"))); // Antar at du ikke vil inkludere "Unknown" i listen
    
        const pickUpLocations = Array.from(new Set(data.map(order => order.pickUpLocation).filter(location => location)));
        setUniquePickUpLocations(pickUpLocations);
    
        const dropOffLocations = Array.from(new Set(data.map(order => order.dropOffLocation).filter(location => location)));
        setUniqueDropOffLocations(dropOffLocations);
    
        setUniqueCompanyCodes(companyCodes);
        setUniqueRepairTypes(repairTypes);
        setUniqueCategories(categories);
        setUniqueDeliveries(deliveries);
      });
    }, []);
    

      useEffect(() => {
        const filteredOrders = allOrders
          .filter(order => !selectedCompanyCode || order.companyCode === selectedCompanyCode)
          .filter(order => !selectedRepairType || order.order[0]?.product === selectedRepairType)
          .filter(order => !selectedCategory || order.order[0]?.category === selectedCategory)
          .filter(order => {
            if (!selectedDelivery) return true;
            if (selectedDelivery === "DropOff") return order.dropOffOrder;
            if (selectedDelivery === "PickUp") return order.pickUpOrder;
            if (selectedDelivery === "Send") return order.sendOrder;
            return false;
          })
          .filter(order => {
            if (filterByCompanyCode === "yes") return order.companyCode;
            if (filterByCompanyCode === "no") return !order.companyCode;
            return true;
          }).filter(order => {
            if (!selectedMonth && !selectedYear) return true;
            
            const [year, month] = order.dateOrdered.split("-").map(Number);
            const isMonthMatch = selectedMonth ? month === parseInt(selectedMonth) + 1 : true;
            const isYearMatch = selectedYear ? year === parseInt(selectedYear) : true;
            
            return isMonthMatch && isYearMatch;
          
          }).filter(order => {
            if (selectedExpressStatus === "") return true;
            if (selectedExpressStatus === "yes") return order.express;
            if (selectedExpressStatus === "no") return !order.express;
            return true;
          }) .filter(order => !selectedPickUpLocation || order.pickUpLocation === selectedPickUpLocation)
          .filter(order => {
            return !selectedDropOffLocation || order.dropOffLocation === selectedDropOffLocation;
          }).sort((a, b) => b.dateOrdered.localeCompare(a.dateOrdered));
        
          setOrders(filteredOrders);
        }, [selectedCompanyCode, selectedRepairType, selectedCategory, selectedDelivery, allOrders, filterByCompanyCode, selectedMonth, selectedYear, selectedExpressStatus, selectedPickUpLocation, selectedDropOffLocation]);

      const handleFilterByCompanyCodeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setFilterByCompanyCode(event.target.value as "yes" | "no" | "");
      };
  
    const handleCompanyCodeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedCompanyCode(event.target.value);
    };

    const handleRepairTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedRepairType(event.target.value);
      };
    
      const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCategory(event.target.value);
      };
    
      const handleDeliveryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedDelivery(event.target.value);
      };

const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  setSelectedMonth(event.target.value);
};
const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  setSelectedYear(event.target.value);
};

const handleExpressChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  setSelectedExpressStatus(event.target.value);
};

const handlePickUpLocationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  setSelectedPickUpLocation(event.target.value);
};

const formatDateToDayMonthYear = (isoDate: string): string => {
  const [year, month, day] = isoDate.split("-").map(Number);
  return `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year}`;
};

const calculateKeyMetrics = (orders: Order[]) => {
  const filteredOrders = orders.filter(order => {
    const price = Number(order.price);
    return price >= 10 && price <= 10000;
  });

  const totalNumberOfOrders = filteredOrders.length;
  const totalRevenue = filteredOrders.reduce((sum, order) => sum + Number(order.price), 0);
  const averagePrice = totalNumberOfOrders > 0 ? totalRevenue / totalNumberOfOrders : 0;

  return {
    totalNumberOfOrders,
    averagePrice: averagePrice.toFixed(0),
    totalRevenue: totalRevenue.toFixed(0),
  };
};

const [metrics, setMetrics] = useState<{ totalNumberOfOrders: number, averagePrice: string, totalRevenue: string }>({ totalNumberOfOrders: 0, averagePrice: '0.00', totalRevenue: '0.00' });

  useEffect(() => {
    const calculatedMetrics = calculateKeyMetrics(orders);
    setMetrics({
      totalNumberOfOrders: calculatedMetrics.totalNumberOfOrders,
      averagePrice: calculatedMetrics.averagePrice,
      totalRevenue: calculatedMetrics.totalRevenue
    });
  }, [orders]);

  return (
    <div className={css.adminContainer}>
      <h1 className={css.adminTitle}>Admin og analyse {id}</h1>

      <div className={css.keyMetrics}>
  <div className={`${css.metricBox} ${css.primary}`}>
    <span>Antall bestillinger:</span>
    <span>{metrics.totalNumberOfOrders}</span>
  </div>
  <div className={`${css.metricBox} ${css.secondary}`}>
    <span>Gjennomsnittlig totalpris:</span>
    <span>{metrics.averagePrice} kr</span>
  </div>
  <div className={`${css.metricBox} ${css.tertiary}`}>
    <span>Sum omsetning:</span>
    <span>{metrics.totalRevenue} kr</span>
  </div>
</div>

      
          <div className={css.filterContainer}>

          <label htmlFor="companyCodePresenceFilter">Firmakunde? </label>
      <select id="companyCodePresenceFilter" value={filterByCompanyCode} onChange={handleFilterByCompanyCodeChange}>
        <option value="">Alle</option>
        <option value="yes">Ja</option>
        <option value="no">Nei</option>
      </select>

          <label htmlFor="companyCodeFilter">Type firmakode: </label>
          <select id="companyCodeFilter" value={selectedCompanyCode} onChange={handleCompanyCodeChange}>
            <option value="">Alle</option>
            {uniqueCompanyCodes.map(code => (
              <option key={code} value={code}>{code}</option>
            ))}
          </select>

          <label htmlFor="repairTypeFilter">Reparasjonstype: </label>
        <select id="repairTypeFilter" value={selectedRepairType} onChange={handleRepairTypeChange}>
          <option value="">Alle</option>
          {uniqueRepairTypes.map(type => (
            <option key={type} value={type}>{type}</option>
          ))}
        </select>

        <label htmlFor="categoryFilter">Kategori: </label>
        <select id="categoryFilter" value={selectedCategory} onChange={handleCategoryChange}>
          <option value="">Alle</option>
          {uniqueCategories.map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>

        <label htmlFor="deliveryFilter">Levering: </label>
        <select id="deliveryFilter" value={selectedDelivery} onChange={handleDeliveryChange}>
          <option value="">Alle</option>
          {uniqueDeliveries.map(delivery => (
            <option key={delivery} value={delivery}>{delivery}</option>
          ))}
        </select> 

        <label htmlFor="expressFilter">Ekspress: </label>
  <select id="expressFilter" value={selectedExpressStatus} onChange={handleExpressChange}>
    <option value="">Alle</option>
    <option value="yes">Ja</option>
    <option value="no">Nei</option>
  </select>         

        <label htmlFor="monthFilter">Måned: </label>
  <select id="monthFilter" value={selectedMonth} onChange={handleMonthChange}>
    <option value="">Alle</option>
    {monthNames.map((name, index) => (
      <option key={index} value={index.toString()}>{name}</option>
    ))}
  </select>

  <label htmlFor="yearFilter">År: </label>
  <select id="yearFilter" value={selectedYear} onChange={handleYearChange}>
    <option value="">Alle</option>
    {yearRange.map(year => (
      <option key={year} value={year.toString()}>{year}</option>
    ))}
  </select>

  <label htmlFor="dropOffLocationFilter">Leveringssted: </label>
<select id="dropOffLocationFilter" value={selectedDropOffLocation} onChange={e => setSelectedDropOffLocation(e.target.value)}>
  <option value="">Alle</option>
  {uniqueDropOffLocations.map(location => (
    <option key={location} value={location}>{location}</option>
  ))}
</select>

  <label htmlFor="pickUpLocationFilter">Hentested: </label>
  <select id="pickUpLocationFilter" value={selectedPickUpLocation} onChange={handlePickUpLocationChange}>
    <option value="">Alle</option>
    {uniquePickUpLocations.map(location => (
      <option key={location} value={location}>{location}</option>
    ))}
  </select>
        </div>

        <div className={css.ordersList}>
      {orders.map(order => (
        <div key={order.id} className={css.orderCard}>
          <h2 className={css.orderTitle}>ID: {order.id.substring(0, 6)} - {formatDateToDayMonthYear(order.dateOrdered)}</h2>
          <p className={css.orderDetail}>
  {order.companyCode 
  ? `Firma: ${order.companyCode}` 
  : `Privatkunde${order.officeCode ? " (Kontoravtale: " + order.officeCode + ")" : ""}`}
</p>
          <p className={css.orderDetail}>Navn: {order.contactInformation.name}</p>
          <p className={css.orderDetail}>E-post: {order.contactInformation.email}</p>
          <p className={css.orderDetail}>Telefon: {order.contactInformation.phoneNumber}</p>
          <p className={css.orderDetail}>Totalpris: {order.price} kr</p>
          <details className={css.orderDetails}>
            <summary>Se mer</summary>
            <div>
            {
  (order.contactInformation.street || order.contactInformation.streetNumber || order.contactInformation.city || order.contactInformation.postCode) && (
    <div>
      <p className={css.smallText}>Adresse: {order.contactInformation.street} {order.contactInformation.streetNumber}, {order.contactInformation.city}, {order.contactInformation.postCode}</p>
    </div>
  )
}
{order.order.map((item, index) => (
  <div key={item.id} className={css.orderItem}>
    <h4 className={css.productName}>Produkt {index + 1}: {item.product}</h4>
    <p className={css.smallText}>Beskrivelse: {item.description}</p>
    <p className={css.smallText}>Kategori: {item.category}</p>
    <p className={css.smallText}>Pris: {item.price} kr</p>
  </div>
))}
             {!order.dropOffOrder && !order.pickUpOrder && (
  <h4>Leveringspris: {order.deliveryPrice} kr</h4>
)}
              {order.express && (
  <h4>Ekspresspris: {order.expressPrice} kr</h4>
)}
{order.dropOffOrder && order.dropOffLocation && (
  <h4>Leveringssted: {order.dropOffLocation}</h4>
)}

{order.pickUpOrder && order.pickUpLocation && (
  <h4>Hentested: {order.pickUpLocation}</h4>
)}

              <a
      href={`https://console.firebase.google.com/u/0/project/fiksefirstapp/firestore/data/~2Forders~2F${order.id}`}
      target="_blank"
      rel="noopener noreferrer"
      className={css.firebaseLink}
    >
      Åpne i Firebase
    </a>
            </div>
          </details>
        </div>
      ))}
    </div>
  </div>
)};

export default AdminHome;