/* eslint-disable */
import React, { useState, ChangeEvent, useEffect, useContext } from "react";
import { CSSTransition } from "react-transition-group";
import { firestore } from '../firebaseConfig';
import "../styles/Popup.scss";
import PopupPricesContext from '../context/PopupPricesContext';

const Popup: React.FC = () => {
  const [showPopup, setShowPopup] = useState<boolean>(false);  // changed to false initially
  const [userAnswer, setUserAnswer] = useState<boolean | null>(null);
  const [userInput, setUserInput] = useState<string>("");
  const [codes, setCodes] = useState<any[]>([]);
  const { validCode, setValidCode, popupPrices, setPopupPrices, isCodeActive, setIsCodeActive } = useContext(PopupPricesContext);

  useEffect(() => {
    const getCodes = async () => {
      const document = await firestore.collection('dynamicPages').doc('popup').get();
      setCodes(document.data()?.codes ?? []);
    };
    getCodes();
  }, []);

  const handleAnswer = (answer: boolean) => {
    setUserAnswer(answer);
    if (!answer) setShowPopup(false);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.target.value.toUpperCase()); // Convert input to uppercase
  };

  useEffect(() => {
    const storedCode = localStorage.getItem('officeCode');
    if (storedCode) {
      setValidCode(storedCode ?? '');
      setIsCodeActive(true); // Setting isCodeActive to true here
    }
  }, [setValidCode, setIsCodeActive]);

  const handleConfirmClick = () => {
    const foundCode = codes.find(code => code.value === userInput.toUpperCase() && code.valid); // Convert userInput to uppercase for comparison
    if (foundCode) {
      setValidCode(foundCode.value);
      localStorage.setItem('officeCode', foundCode.value);
      setIsCodeActive(true); // Setting isCodeActive to true here
      setShowPopup(false); // Ensure the popup is closed when a valid code is confirmed
    }
  };

  const handlePopupClick = () => {
    const storedCode = localStorage.getItem('officeCode');
    if (storedCode) {
      const userConfirmed = window.confirm("Vil du fjerne rabattkoden?");
      if (userConfirmed) {
        localStorage.removeItem('officeCode');
        setValidCode('');
        setIsCodeActive(false);
      }
    } else {
      setShowPopup(true);
    }
  };

  return (
    <>
      <button className={`popup ${validCode ? 'valid-code' : 'iconButton'}`} onClick={handlePopupClick}>
        {validCode ? `${validCode} registrert` : 'Kontoravtale'}
      </button>
      <CSSTransition in={showPopup} timeout={300} classNames="popup" unmountOnExit>
        <div className={`popup ${validCode ? 'valid-code' : 'startButton'}`}>
          {validCode ? (
            <p>{validCode} registrert</p>
          ) : (
            <>
              {userAnswer === null ? (
                <div>
                  <h2>Har dere en kontoravtale?</h2>
                  <button className="confirm-button" onClick={() => handleAnswer(true)}>Ja</button>
                  <button className="close-button" onClick={() => handleAnswer(false)}>Nei</button>
                </div>
              ) : (
                <div>
                  <input
                    type="text"
                    value={userInput}
                    onChange={handleInputChange}
                    placeholder="Kontorkode"
                  />
                  <button className="confirm-button" onClick={handleConfirmClick}>Bekreft</button>
                  <button className="close-button" onClick={() => handleAnswer(false)}>Lukk</button>
                </div>
              )}
            </>
          )}
        </div>
      </CSSTransition>
    </>
  );
};

export default Popup;