import React from "react";
import css from "../styles/InfoBox.module.scss";

export interface Props {
  title: string;
  paragraph: string;
  paragraph2?: string;
  style?: React.CSSProperties;
}

const InfoBox: React.FC<Props> = ({ title, paragraph, paragraph2, style }) => {
  return (
    <div className={css.InfoBox}>
      <h2>{title}</h2>
      <p style={style && style}>{paragraph}</p>
      {paragraph2 && <p style={style && style}>{paragraph2}</p>}
    </div>
  );
};

export default InfoBox;
