import React from "react";
import css from "../styles/NotFound.module.scss";

const NotFound = () => {
  return (
    <div className={css.NotFound}>
      <h1>404 error</h1>
      <p>This page does not exist</p>
    </div>
  );
};

export default NotFound;
